import React from "react";
import { Container, Row, Col } from "reactstrap";
const Masterplan = () => {
	return (
		<div className="section-with-space" id="masterplan">
			<Container>
				<Row>
					<Col sm="12">
						<h2 className="text-center my-5">Masterplan 360º</h2>
					</Col>
				</Row>
                <Row>
                    <Col sm="12">
                        <div style={{width:'100%', height:'500px'}}>
                            <iframe
                                style={{width:'100%',height:'100%', border:'0'}}
                                title="masterplan"
                                src="https://www.lanube360.com/losrobles/"
                            ></iframe>
                        </div>
                    </Col>
                </Row>
			</Container>
		</div>
	);
};
export default Masterplan;
