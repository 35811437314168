/*eslint-disable*/
import React, { Fragment } from "react";

// reactstrap components
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	CardTitle,
	Container,
	Row,
	Col,
} from "reactstrap";
const Urbanizacion = () => {
  return(
    <div className="section section-image" id="urbanizacion" style={{
      backgroundImage:
        "url(" +
          require("assets/img/proyecto/bg-urbanizacion.jpg").default +
        ")",
    }}>
						<Container>
							<Row>
								<Col className="ml-auto mr-auto text-center pb-3" md="8">
									<h2 className="title text-white fw">Urbanización</h2>
								</Col>
							</Row>
							<Row>
								<Col md="4">
									<div className="info">
										<div className="icon text-white">
											<i className="nc-icon icon-lock" />
										</div>
										<div className="description">
											<h4 className="info-title fw">Acceso y Seguridad</h4>
											<p className="description fw">
												Portón de acceso con control remoto.
											</p>
											<p className="description fw">
												Acceso principal con tranqueras de Coihue.
											</p>
										</div>
									</div>
								</Col>
								<Col md="4">
									<div className="info">
										<div className="icon text-white">
											<i className="nc-icon icon-bulb-63" />
										</div>
										<div className="description">
											<h4 className="info-title fw">Luz</h4>
											<p className="description fw">
												Soterrada: Cableado bajo tierra. Sin postes ni cables a
												la vista.
											</p>
											<p className="description fw">
												Luminarias LED: Línea de iluminación en caminos
												interiores.
											</p>
											<p className="description fw">Proveedor de luz (Saesa).</p>
										</div>
									</div>
								</Col>
								<Col md="4">
									<div className="info">
										<div className="icon text-white">
											<i className="nc-icon icon-water-sink" />
										</div>
										<div className="description">
											<h4 className="info-title fw">Agua Potable</h4>
											<p className="description fw">
												Red de agua potable extraída de pozo profundo exclusivo
												para Los Robles.
											</p>
											<p className="description fw">
												Presión de agua estabilizada con equipo de bombas para
												mantener presión en todas las parcelas.
											</p>
										</div>
									</div>
								</Col>
								<Col md="4">
									<div className="info">
										<div className="icon text-white">
											<i className="nc-icon icon-road-2" />
										</div>
										<div className="description">
											<h4 className="info-title fw">Caminos</h4>
											<p className="description fw">
												Caminos de tierra trabajados con estabilizado y levanta
												polvo EcoSoil.
											</p>
											<p className="description fw">
												Caminos principales de 10m de ancho.
											</p>
										</div>
									</div>
								</Col>
								<Col md="4">
									<div className="info">
										<div className="icon text-white">
											<i className="nc-icon nc-sun-fog-29" />
										</div>
										<div className="description">
											<h4 className="info-title fw">Internet</h4>
											<p className="description fw">
												Cableado para acceder a fibra óptica.
											</p>
										</div>
									</div>
								</Col>
								<Col md="4">
									<div className="info">
										<div className="icon text-white">
											<i className="nc-icon icon-waste-recycling" />
										</div>
										<div className="description">
											<h4 className="info-title fw">Basura y Reciclaje</h4>
											<p className="description fw">
												Contenedores de basura y contenedor de reciclaje
												exclusivo para los Robles.
											</p>
										</div>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
  )
}
const AreasComunes = () => {
  return(
    <div className="features-1 section-gray" id="areascomunes">
						<Container>
							<Row>
								<Col className="ml-auto mr-auto text-center pb-3" md="8">
									<h2 className="title">Areas Comunes</h2>
								</Col>
							</Row>
							<Row>
								<Col md="4">
									<div className="info">
										<div className="icon icon-danger">
											<i className="nc-icon icon-plant-ground" />
										</div>
										<div className="description">
											<h4 className="info-title">Huerta Comunitaria</h4>
										</div>
									</div>
								</Col>
								<Col md="4">
									<div className="info">
										<div className="icon icon-danger">
											<i className="nc-icon icon-land" />
										</div>
										<div className="description">
											<h4 className="info-title">Quinta de Frutales</h4>
										</div>
									</div>
								</Col>
								<Col md="4">
									<div className="info">
										<div className="icon icon-danger">
											<i className="nc-icon icon-picnic-basket" />
										</div>
										<div className="description">
											<h4 className="info-title">Zona de Picnic</h4>
										</div>
									</div>
								</Col>
								<Col md="4">
									<div className="info">
										<div className="icon icon-danger">
											<i className="nc-icon icon-fire" />
										</div>
										<div className="description">
											<h4 className="info-title">Fogón patagónico equipado con luz y agua</h4>
										</div>
									</div>
								</Col>
								<Col md="4">
									<div className="info">
										<div className="icon icon-danger">
											<i className="nc-icon icon-walk-2" />
										</div>
										<div className="description">
											<h4 className="info-title">Bosque Nativo con senderos y pasarelas</h4>
										</div>
									</div>
								</Col>
								<Col md="4">
									<div className="info">
										<div className="icon icon-danger">
											<i className="nc-icon icon-duck" />
										</div>
										<div className="description">
											<h4 className="info-title">Laguna</h4>
										</div>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
  )
}
const Descripcion = () => {
  return(
    <div id="descripcion" className="project-4 section section-dark">
				<Container>
					<Row>
						<Col className="ml-auto mr-auto text-center" md="8">
							<h2 className="title">Los Robles</h2>
							<h5 className="description">
								Nuestro proyecto convive con la tranquilidad de vivir en la
								naturaleza y el acceso a lo propio de la ciudad.
							</h5>
						</Col>
					</Row>
					<div className="space-top" />
					<Row>
						<Col className="ml-auto" md="5">
							<Card
								data-background="image"
								style={{
									backgroundImage:
										"url(" +
										require("assets/img/proyecto/elproyecto.jpg").default +
										")",
								}}
							>
							</Card>
						</Col>
						<Col className="mr-auto" md="5">
							<Card className="card-plain">
								<CardBody>
									<a href="#pablo" onClick={(e) => e.preventDefault()}>
										<CardTitle tag="h3">EL Proyecto</CardTitle>
									</a>
									<p className="card-description">
										Vive la diversidad geográfica en Los Robles, una parcelación
										inserta en los paisajes característicos de la región de Los
										Lagos en el sur de Chile. Los Robles cuenta con 88
										exclusivas parcelas desde 5.000 m2 y amplios caminos
										rodeados de 6 hectáreas de áreas comunes, con el fin de
										integrar a la comunidad con el entorno natural.
									</p>
								</CardBody>
							</Card>
						</Col>
					</Row>
					<br />  
				</Container>
			</div>
  )
}
function Proyecto() {
	return (
		<div id="proyecto">
      <Descripcion />
      <Urbanizacion />
      <AreasComunes />
		</div>
	);
}

export default Proyecto;
