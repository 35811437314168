import React, { useState } from "react";
import { Container, Row, Col, Card, CardTitle, CardBody, Form, FormGroup, Input, Button, Spinner } from "reactstrap";
import Axios from "axios"

const INITIAL_STATE = {
	nombre: "",
	email: "",
	message: "",
};
function Formulario() {
	const [data, setData] = useState(INITIAL_STATE);
	const [isLoading, setIsLoading] = useState(false);
	const handleOnChange = (event) => {
		setData({ ...data, [event.target.name]: event.target.value });
	};
	const handleOnSubmit = (event) => {
		event.preventDefault();
		setIsLoading(true);
		const { nombre, email, message } = data;
		const text = `
			Nombre: ${nombre} 
			Email: ${email} 
			Mensaje: ${message}
        `
      	const info = {
			to: "nicolas@lrobles.cl",
			replyTo: email,
			subject:'Formulario Contacto',
			text: text
		}
		Axios.post("https://us-central1-firemailer.cloudfunctions.net/submitContactoFZ", info)
			.then((res) => {
				console.log(`mensaje enviado: ${res.data.isEmailSend}`);
				setIsLoading(false);
			})
			.catch((error) => console.log(`ha ocurrido un error ${error}`));

		setData(INITIAL_STATE);
	};
	
	const { nombre, email, message } = data;
	const isDisabled = nombre === "" || email === "" ;
	return (
		<Form id="contact-form" method="post" role="form" onSubmit={handleOnSubmit}>
			<CardBody>
				<FormGroup className="label-floating">
					<label className="control-label">Nombre</label>
					<Input
						name="nombre"
						placeholder="Tu nombre"
						type="text"
						value={nombre}
						onChange={handleOnChange}
					/>
				</FormGroup>

				<FormGroup className="label-floating">
					<label className="control-label">E-mail</label>
					<Input 
            name="email" 
            placeholder="Tu email" 
            type="email" 
            value={email}
            onChange={handleOnChange}
          />
				</FormGroup>
				<FormGroup className="label-floating">
					<label className="control-label">Mensaje</label>
					<Input
						id="message"
						name="message"
						placeholder="Tu mensaje"
						type="textarea"
						rows="6"
            value={message}
            onChange={handleOnChange}
					/>
				</FormGroup>
				<Row>
					<Col md="6">
            {isLoading ? <Spinner/>:
              <Button
                disabled={isDisabled}
                className="pull-left"
                color="primary"
                type="submit"
              >
                Enviar
              </Button>
            }
					</Col>
				</Row>
			</CardBody>
		</Form>
	);
}

function Contacto() {
	return (
		<>
			<div className="section section-contactus cd-section" id="contacto">
				<div
					className="contactus-1 section-image"
					style={{
						backgroundImage:
							"url(" +
							require("assets/img/contacto/contacto.jpg").default +
							")",
					}}
				>
					<Container>
						<Row>
							<Col className="ml-auto mr-auto" md="10">
								<Card className="card-contact no-transition">
									<CardTitle className="text-center" tag="h3">
										Contacto
									</CardTitle>
									<Row>
										<Col className="ml-auto" md="5">
											<CardBody>
												<div className="info info-horizontal">
													<div className="icon icon-info">
														<i className="nc-icon icon-mail" />
													</div>
													<div className="description">
														<h4 className="info-title">Escríbenos</h4>
														<p>
															<a href="mailto:nicolas@lrobles.cl">
																nicolas@lrobles.cl
															</a>
														</p>
													</div>
												</div>
												<div className="info info-horizontal">
													<div className="icon icon-danger">
														<i className="nc-icon icon-phone" />
													</div>
													<div className="description">
														<h4 className="info-title">Llamanos</h4>
														<p>
															<br />
															<a href="tel:+56940676514">+56 9 4067 65142</a>
															<br />
															<br />
														</p>
													</div>
												</div>
											</CardBody>
										</Col>
										<Col className="mr-auto" md="5">
											<Formulario />
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		</>
	);
}

export default Contacto;

