export const photos = [
    {
      src: require('assets/img/galeria/1.jpg').default,
      width: 1280,
      height: 960,
      alt:'',
      title:''
    },
    {
      src: require('assets/img/galeria/2.jpg').default,
      width: 1280,
      height: 960,
      title:''
    },
    {
      src: require('assets/img/galeria/13.jpg').default,
      width: 461,
      height: 1024,
      title:''
    },
    {
      src: require('assets/img/galeria/3.jpg').default,
      width: 1280,
      height: 960,
      title:''
    },
    {
      src: require('assets/img/galeria/4.jpg').default,
      width: 1280,
      height: 960,
      title:''
    },
    {
      src: require('assets/img/galeria/5.jpg').default,
      width: 1280,
      height: 960,
      title:''
    },
    {
      src: require('assets/img/galeria/6.jpg').default,
      width: 1280,
      height: 960,
      title:''
    },
    {
      src: require('assets/img/galeria/7.jpg').default,
      width: 1280,
      height: 958,
      title:''
    },
    {
      src: require('assets/img/galeria/8.jpg').default,
      width: 1280,
      height: 960,
      title:''
    },
    {
      src: require('assets/img/galeria/9.jpg').default,
      width: 960,
      height: 1280,
      title:''
    },
    {
      src: require('assets/img/galeria/10.jpg').default,
      width: 1280,
      height: 960,
      title:''
    },
    {
      src: require('assets/img/galeria/11.jpg').default,
      width: 1280,
      height: 960,
      title:''
    },
    {
      src: require('assets/img/galeria/12.jpg').default,
      width: 1280,
      height: 960,
      title:''
    },
    
  ];
  