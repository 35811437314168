import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
// reactstrap components

import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";
// core components
import Logo from '../../assets/img/logo.png'
function ColorNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 499 ||
        document.body.scrollTop > 499
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 500 ||
        document.body.scrollTop < 500
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      <Navbar
        className={classnames("fixed-top pt-0", navbarColor)}
        expand="lg"
        id="navbar-main"
      >
        <Container>
          <div className="navbar-translate">
            <NavbarBrand id="navbar-brand" className="py-0" to="/#inicio" tag={Link}>
              <img src={Logo} alt="logo" style={{width:'100px'}}/>
            </NavbarBrand>
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle className="mr-2" color="default" caret nav>
                    El Proyecto
                </DropdownToggle>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem to="/#descripcion" tag={Link}>
                    Descripción
                  </DropdownItem>
                  <DropdownItem to="/#urbanizacion" tag={Link}>
                    Urbanización
                  </DropdownItem>
                  <DropdownItem to="/#areascomunes" tag={Link}>
                    Areas comunes
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              
              <NavItem>
                <NavLink tag={Link} to="/#masterplan">
                    Masterplan 360
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/#galeria">
                    Galería
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/#video">
                    Video
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/#ubicacion">
                    Ubicación
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/#contacto">
                    Contacto
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ColorNavbar;
