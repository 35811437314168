import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";

// core components
const items = [
  {
    src:
      "url(" +
      require("assets/img/header/banner1.jpeg").default +
      ")",
    content: (
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h1 className="title fw">Los Robles</h1>
            <h5 className="fw">
              Proyecto armonioso y equilibrado 
            </h5>
            <br />
            <div className="buttons">
              <Button
                className="btn-round"
                color="danger"
                to="/#proyecto"
                tag={Link}
                size="lg"
              >
                Saber más
              </Button>
              <Button
                className="btn-neutral btn-just-icon"
                color="link"
                href="https://www.instagram.com/@losrobles_i"
                target="blank"
              >
                <i className="fa fa-instagram" />
              </Button>
              <Button
                className="btn-neutral btn-just-icon"
                color="link"
                href="https://www.facebook.com/losrobles_i"
                target="blank"
              >
                <i className="fa fa-facebook-square" />
              </Button>
              
            </div>
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
  },
  {
    src:
      "url(" +
      require("assets/img/header/banner2.jpeg").default +
      ")",
    content: (
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h1 className="title fw">Los Robles</h1>
            <h5 className="fw">
              Invierte seguro
            </h5>
            <h5 className="fw">Desde 1.700 UF</h5>
            <br />
            <h6>Contáctanos:</h6>
            <div className="buttons">
              
              <Button
                className="btn-neutral btn-just-icon"
                color="link"
                href="https://www.facebook.com/losrobles_i"
                target="blank"
              >
                <i className="fa fa-facebook-square" />
              </Button>
              
              <Button
                className="btn-neutral btn-just-icon"
                color="link"
                href="https://www.instagram.com/@losrobles_i"
                target="blank"
              >
                <i className="fa fa-instagram" />
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
  },
  {
    src:
      "url(" +
      require("assets/img/header/banner3.jpeg").default +
      ")",
    content: (
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h1 className="title fw">Los Robles</h1>
            <h5 className="fw">
              Urbanización respetuosa 
            </h5>
            <br />
            <div className="buttons">
              <Button
                className="btn-neutral"
                color="link"
                href="https://www.facebook.com/losrobles_i"
                target="blank"
                size="lg"
              >
                <i className="fa fa-facebook-square" />
            
              </Button>
              <Button
                className="btn-neutral btn-just-icon"
                color="link"
                href="https://www.instagram.com/@losrobles_i"
                target="blank"
              >
                <i className="fa fa-instagram" />
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
  },
];

function Header() {
  // carousel - header 3
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  return (
    <>
        <div className="header-3" id="inicio">
          <div className="page-carousel">
            <div className="filter" />
            <Carousel activeIndex={activeIndex} next={next} previous={previous}>
              <CarouselIndicators
                items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
              />
              {items.map((item) => {
                return (
                  <CarouselItem
                    onExiting={onExiting}
                    onExited={onExited}
                    key={item.src}
                  >
                    <div
                      className="page-header"
                      style={{ backgroundImage: item.src }}
                    >
                      <div className="filter" />
                      <div className="content-center">{item.content}</div>
                    </div>
                  </CarouselItem>
                );
              })}
              <a
                className="left carousel-control carousel-control-prev"
                data-slide="prev"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  previous();
                }}
                role="button"
              >
                <span className="fa fa-angle-left" />
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="right carousel-control carousel-control-next"
                data-slide="next"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  next();
                }}
                role="button"
              >
                <span className="fa fa-angle-right" />
                <span className="sr-only">Next</span>
              </a>
            </Carousel>
          </div>
        </div>     
   
    </>
  );
}

export default Header;
