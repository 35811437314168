import React, { useEffect } from "react";
import Navbar from '../components/Navbar'
import Header from '../components/Header'
import Proyecto from '../components/Proyecto'
import Masterplan from '../components/Masterplan'
import Galeria from '../components/Galeria'
import Ubicacion from '../components/Ubicacion'
import Video from '../components/Video'
import Contacto from '../components/Contacto'
function Inicio() {
  document.documentElement.classList.remove("nav-open");
  useEffect(() => {
    document.body.classList.add("section-page");
    
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#/") + 2
    );
    var hrefId = href.substring(href.lastIndexOf("#") + 1);
    if (href.lastIndexOf("#") > 0) {
      document.getElementById(hrefId).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
    return function cleanup() {
      document.body.classList.remove("section-page");
    };
  });
  return (
    <>
      <Navbar />
      <Header />
      <Proyecto />
      <Masterplan />
      <Galeria />
      <Video />
      <Ubicacion />
      <Contacto />
    </>
  );
}

export default Inicio;
