import React from "react";
import { Container, Row, Col } from "reactstrap";
import Map from "./Map.js";
function Ubicacion() {
	return (
		<div className="section" id="ubicacion">
			<Container>
				<Row>
					<Col sm="12">
						<h2 className="text-center my-5">Ubicación</h2>
					</Col>
				</Row>
				<Row>
					<Col md="4">
						<div className="info info-horizontal pt-0 pt-sm-4">
							<div className="icon icon-info">
								<i className="nc-icon icon-map-pin-2" />
							</div>
							<div className="description">
								<h4 className="info-title">Distancias</h4>
								<ul className="list-unstyled pl-0">
									<li>
                                        <strong>Río Frío</strong>  8 minutos 
									</li>
                                    <li>
                                        <strong>Puerto Varas</strong> 30 minutos 
                                    </li>
                                    <li>
                                        <strong>Los Muermos</strong> 10 minutos 
                                    </li>
                                    <li>
                                        <strong>Aeropuerto</strong>  20 minutos 
                                    </li>
                                    <li>
                                        <strong>Playa Estaquilla</strong> 40 minutos 
                                    </li>
                                    <li>
                                        <strong>Puerto Godoy</strong> 40 minutos
                                    </li>
                                    <li>
                                        <strong>Río Maullín </strong> 35 minutos 
                                    </li>
                                    <li>
                                        <strong>Puerto Montt </strong >35 minutos
                                    </li>
								</ul>
							</div>
						</div>
					</Col>
					<Col>
						<Map />
					</Col>
				</Row>
			</Container>
		</div>
	);
}
export default Ubicacion;
