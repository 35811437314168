import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
// pages
import Inicio from './Pages'



ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" render={ props => <Inicio {...props}/>} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
