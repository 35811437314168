import React from 'react'
import { Container, Row, Col } from 'reactstrap' 
const Video = () => {
    return(
        <div className="section" id="video">
            <Container>
                <Row>
                    <Col md="12">
                        <h2 className="text-center my-5">Video</h2>
                    </Col>
                </Row>
                <Row>
                    <div className="embed-responsive embed-responsive-16by9">
                        <video controls preload="auto" volume="0">
                            <source
                                src={require("assets/video/video.m4v").default}
                                type="video/mp4"
                            ></source>
                            Video not supported
                        </video>
                    </div>
                </Row>
            </Container>
        </div>
    )
}
export default Video